import React,{useState} from 'react'
import './radio.css';

function Radio() {
    const [click,setClick] = useState(false);

    const handleClick = () => {
        setClick(!click);
    }

    return (
        <div className={ click ? "radio radio-clicked" : "radio" } onClick={handleClick}>
        </div>
    )
}

export default Radio;
