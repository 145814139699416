import React,{useState} from 'react'
import './check.css';

function Check() {
    const [click,setClick] = useState(false);

    const handleClick = () => {
        setClick(!click);
    }

    return (
        <div className={ click ? "check clicked" : "check" } onClick={handleClick}>
            {click ? <i className="bi bi-check"></i> : null}
        </div>
    )
}

export default Check;
