import React, { Component } from "react";
import TopMenuBar from "./TopMenuBar";
import "../Component/ancillarySetting.css";
// import Calendar from 'react-calendar';
// import 'react-calendar/dist/Calendar.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Check from "./check";
import Radio from './radio';
import AncillaryCheck from "./ancillaryCheck";
import { render } from "fusioncharts";

class AncillarySetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ffpselect1: false,
      ffpselect2: false,
      classSelect1: false,
      classSelect2: false,
      dowselect1: false,
      dowselect2: false,
      dowselect3: false,
      sectorselect:false,
      odselect:false,
      flightselect:false,
    };
  }
  datePick = () => {
      return(
        <DatePicker/>
      );
  };

  ffpClick1 = () => {
    const currentState = this.state.ffpselect1;
    this.setState({ ffpselect1: !currentState });
  };
  ffpClick2 = () => {
    const currentState = this.state.ffpselect2;
    this.setState({ ffpselect2: !currentState });
  };
  classClick1 = () => {
    const currentState = this.state.classSelect1;
    this.setState({ classSelect1: !currentState });
  };
  classClick2 = () => {
    const currentState = this.state.classSelect2;
    this.setState({ classSelect2: !currentState });
  };
  dowClick1 = () => {
    const currentState = this.state.dowselect1;
    this.setState({ dowselect1: !currentState });
  };
  dowClick2 = () => {
    const currentState = this.state.dowselect2;
    this.setState({ dowselect2: !currentState });
  };
  dowClick3 = () => {
    const currentState = this.state.dowselect3;
    this.setState({ dowselect3: !currentState });
  };
  selectClick = () => {
    const currentState = this.state.sectorselect;
    this.setState({ sectorselect: !currentState });
  };
  flightClick = () => {
    const currentState = this.state.flightselect;
    this.setState({ flightselect: !currentState });
  };
  odClick = () => {
    const currentState = this.state.odselect;
    this.setState({ odselect: !currentState });
  };

  handleRangeChange = (name) => (event, newValue) => {
    this.setState({ [name]: newValue });
  };

  render() {
    return (
      <div>
        <TopMenuBar {...this.props} />
        <div className="page-size">
          <div class="add">
            <h2>ANCILLARY SETTING</h2>
          </div>

          <div className="ancillary-head">
            <h3>ANCILLARY</h3>
            <div className="ancillary-checkbox">
              <div className="checkbox-items">
              <AncillaryCheck />
                <p>Upgrade</p>
              </div>
              <div className="checkbox-items">
              <AncillaryCheck />
                <p>Voucher</p>
              </div>
              <div className="checkbox-items">
              <AncillaryCheck />
                <p>Tours</p>
              </div>
              <div className="checkbox-items">
              <AncillaryCheck />
                <p>Spa</p>
              </div>
              <div className="checkbox-items">
              <AncillaryCheck />
                <p>Room</p>
              </div>
            </div>
          </div>

          <div className="ancillary-head">
            <h3>HOTEL SETTING</h3>
            <div className="ancillary-radio">
              <div className="ancillary-checkbox">
                <div className="checkbox-items">
                  <Radio/>
                  <p>Forecast Load Factor</p>
                  <input type="text" value="20"></input>
                </div>
              </div>

              <div className="ancillary-checkbox">
                <div className="checkbox-items">
                <Radio/>
                  <p>Room Quota</p>
                  <input type="text" value="25"></input>
                </div>
              </div>

              <div className="ancillary-checkbox">
                <div className="checkbox-items">
                <Radio/>
                  <p>Availability</p>
                  <input type="text" value="30"></input>
                </div>
              </div>

              <div className="ancillary-checkbox">
                <div className="checkbox-items">
                <Radio/>
                  <p>Room Type</p>
                  <div className="select-container">
                    <div className="date-items select-box" onClick={this.selectClick}>
                      <p>Select Room</p>
                      <i className="bi bi-chevron-down"></i>
                    </div>
                    <div className={this.state.sectorselect ? "sector-container sector-show" : "sector-container"}>
                      <div className="downItems">
                        <Check />
                        <span className="drop-span">HYD-KUL</span>
                      </div>
                      <div className="downItems">
                        <Check />
                        <span className="drop-span">KUL-MEL</span>
                      </div>
                      <div className="downItems">
                        <Check />
                        <span className="drop-span">SYD-KUL</span>
                      </div>
                      <div className="downItems">
                        <Check />
                        <span className="drop-span">KUL-DAC</span>
                      </div>
                      <div className="downItems">
                        <Check />
                        <span className="drop-span">MAA-KUL</span>
                      </div>
                      <div className="downItems">
                        <Check />
                        <span className="drop-span">KUL-MEL</span>
                      </div>
                      <div className="downItems">
                        <Check />
                        <span className="drop-span">BLR-KUL</span>
                      </div>
                      <div className="downItems">
                        <Check />
                        <span className="drop-span">KUL-MEL</span>
                      </div>
                      <div className="downItems">
                        <Check />
                        <span className="drop-span">LEG-KUL</span>
                      </div>
                      <div className="downItems">
                        <Check />
                        <span className="drop-span">KUL-DPS</span>
                      </div>
                      <div className="downItems">
                        <Check />
                        <span className="drop-span">MEl-KUL</span>
                      </div>
                      <div className="downItems">
                        <Check />
                        <span className="drop-span">KUL-CMB</span>
                      </div>
                      <div className="downItems">
                        <Check />
                        <span className="drop-span">CMB-KUL</span>
                      </div>
                      <div className="downItems">
                        <Check />
                        <span className="drop-span">KUL-SGN</span>
                      </div>
                      <div className="downItems">
                        <Check />
                        <span className="drop-span">RGN-KUL</span>
                      </div>
                      <div className="downItems">
                        <Check />
                        <span className="drop-span">KUL-CMB</span>
                      </div>
                      <div className="downItems">
                        <Check />
                        <span className="drop-span">PNH-KUL</span>
                      </div>
                      <div className="downItems">
                        <Check />
                        <span className="drop-span">KUL-CMB</span>
                      </div>
                      <div className="downItems">
                        <Check />
                        <span className="drop-span">SIN-KUL</span>
                      </div>
                      <div className="downItems">
                        <Check />
                        <span className="drop-span">KUL-HAN</span>
                      </div>
                    </div>
                    <div className="date-items select-box" onClick={this.dowClick1}>
                      <p>Select DOW</p>
                      <i className="bi bi-chevron-down"></i>
                    </div>
                    <div className={this.state.dowselect1 ? "dow-container dow-show" : "dow-container"}>
                      <div className="downItems">
                        <Check />
                        <span className="drop-span">Sunday</span>
                      </div>
                      <div className="downItems">
                        <Check />
                        <span className="drop-span">Monday</span>
                      </div>
                      <div className="downItems">
                        <Check />
                        <span className="drop-span">Tuesday</span>
                      </div>
                      <div className="downItems">
                        <Check />
                        <span className="drop-span">Wednesday</span>
                      </div>
                      <div className="downItems">
                        <Check />
                        <span className="drop-span">Thursday</span>
                      </div>
                      <div className="downItems">
                        <Check />
                        <span className="drop-span">Friday</span>
                      </div>
                      <div className="downItems">
                        <Check />
                        <span className="drop-span">Saturday</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

             
            </div>
          </div>



          <div className="ancillary-head data-head">
            <h3>DATA SETTING</h3>
            <div className="data-content">
              <div className="data-para">
                <p>Forecast Load factor</p>
              </div>
              <div className="data-radio">
               
                <div className="checkbox-items">
                <Radio/>
                  <p>Revemax</p>
                </div>
              </div>
            </div>
          </div>

          <div className="ancillary-head data-head">
            <h3>BID PRICE</h3>
            <div className="data-content">
              <div className="data-radio">
                <div className="checkbox-items">
                  <p>Minimum</p>
                  <input type="text" value="75          MYR"></input>
                </div>
                <div className="checkbox-items">
                  <p>Maximum</p>
                  <input type="text" value="200        MYR"></input>
                </div>
              </div>
            </div>
          </div>

          <div className="ancillary-head data-head">
            <h3>DISCOUNT</h3>
            <div className="data-content">
              <div className="data-radio">
                <div className="checkbox-items">
                  <p>Minimum</p>
                  <input type="text" value="10              %"></input>
                </div>
                <div className="checkbox-items">
                  <p>Maximum</p>
                  <input type="text" value="30              %"></input>
                </div>
              </div>
            </div>
          </div>

         
        

          <div className="ancillary-head data-head">
            <h3>PERIOD</h3>
            <div className="slider-content">
              <div className="data-radio">
                <div className="slider-items">
                  <p>Days Before</p>
                  <div className="alerts-setting-form">
                    <div class="form-group">
                      <div className="slider"></div>
                      <div className="range-num">
                        <span>0</span>
                        <span id="range">220</span>
                        <span>365</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="ancillary-head data-head">
            <h3>DATE RANGE</h3>
            <div className="date-content">
              <p>Include Period</p>
              <div>
                <DatePicker className="date-items cal" value="From Date " />
                <i className="bi bi-calendar3"></i>
              </div>
              <div>
                <DatePicker className="date-items cal" value="To Date" />
                <i class="bi bi-calendar3"></i>
              </div>
            </div>
            <br />

            <div className="date-content">
              <p>Exclude Period</p>
              <div>
                <DatePicker className="date-items cal" value="From Date" />
                <i className="bi bi-calendar3"></i>
              </div>
              <div>
                <DatePicker className="date-items cal" value="To Date" />
                <i className="bi bi-calendar3" onClick={this.datePick}></i>
              </div>
            </div>
          </div>

          <div className="ancillary-head add-update">
            <div className="add-btn">
              <button>Add</button>
            </div>
            <div className="add-btn">
              <button>Update</button>
            </div>
          </div>

          <div className="ancillary-head">
            <h3>SETTING LIST</h3>
            <table className="table text-center">
              <tr>
                <th className="text-center">Sr.No</th>
                <th className="text-center">Ancillary</th>
                <th className="text-center">Hotel Setting</th>
                <th className="text-center">Data Setting</th>
                <th className="text-center" colSpan="2">
                  Bid Price
                </th>
                <th className="text-center" colSpan="2">
                  Discount
                </th>
                {/* <th className="text-center" colSpan="2">
                  Classes
                </th>
                <th className="text-center" colSpan="2">
                  FFP
                </th> */}
                <th className="text-center">Period</th>
                <th className="text-center" colSpan="2">
                  Date Range
                </th>
                <th className="text-center" colSpan="6">
                  Action
                </th>
              </tr>
              <tr>
                <th className="sub-head"></th>
                <th className="sub-head"></th>
                <th className="sub-head"></th>
                <th className="sub-head"></th>
                <th className="text-center sub-head">Max</th>
                <th className="text-center sub-head">Min</th>
                <th className="text-center sub-head">Max</th>
                <th className="text-center sub-head">Min</th>
                {/* <th className="text-center sub-head">Include</th>
                <th className="text-center sub-head">Exclude</th>
                <th className="text-center sub-head">Include</th>
                <th className="text-center sub-head">Exclude</th> */}
                <th className="text-center sub-head"></th>
                <th className="text-center sub-head">Include</th>
                <th className="text-center sub-head">Exclude</th>
                {/* <th></th> */}
                
              </tr>
              <tr className="border">
                <td className="border">1</td>
                <td className="border">Upgrade Hotel Option</td>
                <td className="border">Forecast Load Factor(20)</td>
                <td className="border">Revemax</td>
                <td className="border">MYR 75</td>
                <td className="border">MYR 200</td>
                <td className="border">10%</td>
                <td className="border">30%</td>
                {/* <td className="border">F</td>
                <td className="border">C</td>
                <td className="border">Bronze</td>
                <td className="border">Silver</td> */}
                <td className="border">220</td>
                <td className="border">
                  01/19/20-
                  <br />
                  01/25/20
                </td>
                <td className="border">
                  01/19/20-
                  <br />
                  01/25/20
                </td>
                <td className="border border-btn" colSpan="3">
                  <button className="edit-btn">Edit</button>
                </td>
                <td className="border border-btn" colSpan="3">
                  <button className="edit-btn">Delete</button>
                </td>
              </tr>

              <tr className="border">
                <td className="border">2</td>
                <td className="border">Hotel Select,Room Type</td>
                <td className="border">Room Quota(50)</td>
                <td className="border">Revemax</td>
                <td className="border">MYR 75</td>
                <td className="border">MYR 200</td>
                <td className="border">10%</td>
                <td className="border">30%</td>
                {/* <td className="border">F</td>
                <td className="border">C</td>
                <td className="border">Bronze</td>
                <td className="border">Silver</td> */}
                <td className="border">220</td>
                <td className="border">
                  01/19/20-
                  <br />
                  01/25/20
                </td>
                <td className="border">
                  01/19/20-
                  <br />
                  01/25/20
                </td>
                <td className="border border-btn" colSpan="3">
                  <button className="edit-btn">Edit</button>
                </td>
                <td className="border border-btn" colSpan="3">
                  <button className="edit-btn">Delete</button>
                </td>
              </tr>

              <tr>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
              </tr>

              <tr>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
              </tr>
              <tr>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
              </tr>
              <tr>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
              </tr>
              <tr>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
              </tr>
              <tr>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
                <td colspan="1">&nbsp;</td>
              </tr>
            </table>
          </div>
          
        </div>
      </div>
    );
  }
}
export default AncillarySetting;
