import React, { Component } from "react";
import APIServices from '../../../../API/apiservices';
import constant from '../../../../Constants/validator'

const apiServices = new APIServices();

class Cards extends Component {
    constructor(props) {
        super();
        this.state = {
            cardData: [],
            loading: false,
        };
    }

    componentWillReceiveProps = (props) => {
        this.getCardData(props)
    }

    getCardData = (props) => {
        var self = this;
        const { startDate, endDate, regionId, countryId, cityId } = props;
        self.setState({ loading: true, cardData: [] })
        apiServices.getPOSCards(startDate, endDate, regionId, countryId, cityId).then((data) => {
            self.setState({ loading: false })
            if (data) {
                self.setState({
                    cardData: data
                })
            }
        });
    }

    render() {

        return (
            <div className='cards-main' >
                        <div id="card_data" className="cards" >
                            <div className='card-top'>
                                <div className="count_top"> Email Sent </div>
                                <div className="count"> 1M </div>
                            </div>
                        </div>
                        <div id="card_data" className="cards" >
                            <div className='card-top'>
                                <div className="count_top"> Email Open </div>
                                <div className="count"> 100K </div>
                            </div>
                        </div>
                        <div id="card_data" className="cards" >
                            <div className='card-top'>
                                <div className="count_top"> Email Click </div>
                                <div className="count"> 10K </div>
                            </div>
                        </div>
                        <div id="card_data" className="cards" >
                            <div className='card-top'>
                                <div className="count_top"> Conversion </div>
                                <div className="count"> 1K </div>
                            </div>
                        </div>
                        <div id="card_data" className="cards" >
                            <div className='card-top'>
                                <div className="count_top"> Revenue </div>
                                <div className="count"> 500K <span>(MYR)</span></div>
                            </div>
                        </div>
                        <div id="card_data" className="cards" >
                            <div className='card-top'>
                                <div className="count_top"> Average Fare </div>
                                <div className="count"> 500 <span>(MYR)</span></div>
                            </div>
                        </div>
                     <div />
            </div>
        )
    }
}

export default Cards;
