import React,{useState} from 'react'
import './AncillaryCheck.css';

function AncillaryCheck() {
    const [click,setClick] = useState(false);

    const handleClick = () => {
        setClick(!click);
    }

    return (
        <div className={ click ? "anci-check anci-clicked" : "anci-check" } onClick={handleClick}>
            {click ? <i className="bi bi-check"></i> : null}
        </div>
    )
}

export default AncillaryCheck;
